<template>
    <div>
        <report :title="$route.name" :parameters="parameters" @refresh="getReportData()" :loading="loading" @exportReport="exportReport">
            <template v-slot:content>
                <v-row>
                    <v-col cols="5" offset="7">
                        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Filter" single-line hide-details clearable></v-text-field>
                    </v-col>
                </v-row>
                
                <v-data-table :headers="headers" :items="rptData" :search="filter" :items-per-page="200">
                    <template v-slot:item.revenue="{ item }">
                        {{ formatMoney( item.revenue ) }}
                    </template>
                    <template v-slot:item.unitAvg="{ item }">
                        {{ formatMoney( item.unitAvg ) }}
                    </template>
                    <template v-slot:item.totalAvg="{ item }">
                        {{ formatMoney( item.totalAvg ) }}
                    </template>

                    <template v-slot:body.append="{ headers, items }">
                        <tr class="totals" v-if="items.length > 0">
                            <td v-for="(col, i) in headers" :key="i" :class="col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'">
                                {{ summarize( col, i ) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </template>
        </report>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Report from '@/components/Report.vue';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import moment from 'moment';

export default {
    data: () => {
        return {
            parameters: [{ 
                type: 'date', 
                key: 'pdate', 
                label: 'Date', 
                value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)], 
                visible: true, 
                icon: '' 
            },{
                type: 'select', 
                key: 'selectStore', 
                label: 'Origin store', 
                value: { id: '%', text: 'All' }, 
                visible: true, 
                icon: '',
                options: [
                    { id: '%', text: 'All' }, 
                    { id: 3, text: '65 de Infanteria' }, 
                    { id: 6, text: 'Arecibo' }, 
                    { id: 9, text: 'Bayamon' }, 
                    { id: 4, text: 'Caguas' }, 
                    { id: 5, text: 'Catano' }, 
                    { id: 8, text: 'Mayaguez' }, 
                    { id: 7, text: 'Ponce' }, 
                    { id: 2, text: 'San Juan' },  
                    { id: 1, text: 'Web' },
                    { id: 10, text: 'Winter Park' } 
                ]
            },{
                type: 'select', 
                key: 'selectDateType', 
                label: 'Date Type', 
                value: { id: 'order', text: 'Book' }, 
                visible: true, 
                icon: '',
                options: [
                    { id: 'order', text: 'Book' }, 
                    { id: 'use', text: 'Use' }
                ]
            }],
            headers: [
                { text: 'Store', value: 'storeName', sortable: true, summarizeFn: '' },
                { text: 'Units', value: 'paidUnits', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'integer' },
                { text: 'Canceled', value: 'canceledUnits', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'integer' },
                { text: 'Total', value: 'totalUnits', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'integer' },
                { text: 'Revenue', value: 'revenue', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'money' },
                { text: 'Unit Avg.', value: 'unitAvg', align: 'right', sortable: true, summarizeFn: 'avg', formatter: 'money' },
                { text: 'Total Avg.', value: 'totalAvg', align: 'right', sortable: true, summarizeFn: 'avg', formatter: 'money' },
            ],
            rptData: [],
            filter: '',
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        })
    },
    created() {
        if( !_st.isEmpty( this.user.privileges['reportAccess'] ) )
            this.parameters[1].visible = false;
    },
    methods: {
        async getReportData() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/rpt/rentunit/${this.parameters[0].value[0]}/${this.parameters[0].value[1]}/${encodeURI( this.parameters[1].value.id )}/${this.parameters[2].value.id}`);
                this.loading = false;
                
                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' )
                    return;
                }
                
                this.rptData = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        formatDate( date ) {
            let m = moment( date, 'YYYY-MM-DD HH:mm:ss' );
            return m.format( 'YYYY-MM-DD hh:mm a' );
        },
        summarize( col ) {
            let data = this.rptData;
            let result = 0;

            if( data.length == 0 )
                return;

            // Summarize data
            if( col.summarizeFn == 'sum') {
                result = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b[col.value] == null ? 0 : b[col.value]);
                }, 0);
            }
            else if( col.summarizeFn == 'avg' && col.value == 'unitAvg') {
                let totalUnits = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b['paidUnits'] == null ? 0 : b['paidUnits']);
                }, 0);
                let totalRevenue = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b['revenue'] == null ? 0 : b['revenue']);
                }, 0);

                result = totalRevenue / totalUnits;
            }
            else if( col.summarizeFn == 'avg' && col.value == 'totalAvg') {
                let totalUnits = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b['totalUnits'] == null ? 0 : b['totalUnits']);
                }, 0);
                let totalRevenue = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b['revenue'] == null ? 0 : b['revenue']);
                }, 0);

                result = totalRevenue / totalUnits;
            }

            // Apply formatter
            switch( col.formatter ) {
                case 'money': return this.formatMoney( result );
                case 'integer': return this.formatNumber( result, 0 );
                case 'na': return result;
            }
        },
        exportReport( format ) {
            if( format == 'pdf' ) this.generatePdf();
            else if( format == 'csv' ) this.exportCsv();
        },
        generatePdf() {
            let data = this.createPdfArray( this.headers, this.rptData );
            this.exportPdf( this.headers, data );
        },
        exportCsv() {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += this.createCsvArray(this.headers, this.rptData).map(item => item.join(',')).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", encodeURI( this.$route.name ) + ".csv");
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
        },
    },
    components: {
        Report
    }
}
</script>

<style scoped>
tr.totals td {
    background-color: #ececec;
    height: auto;
    border-top: 3px double #000;
}
</style>