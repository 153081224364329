<template>
    <div>
        <v-toolbar dark dense>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('refresh')">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="exportDialog = true">
                <v-icon>mdi-file-download-outline</v-icon>
            </v-btn>
            <template v-slot:extension v-if="!hideParameters">
                <span class="mr-3">Parameters: </span>
                <template v-for="param in localVisibleParameters">
                    <parameter v-model="param.value" :key="param.key"></parameter>
                </template>
            </template>
        </v-toolbar>

        <v-card flat :loading="loading">
            <v-card-text>
                <slot name="content">Report content</slot>
            </v-card-text>
        </v-card>

        <v-dialog v-model="exportDialog" max-width="500">
            <v-card>
                <v-card-title>Export</v-card-title>
                <v-card-subtitle>Select which file format you want to download</v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6" lg="6">
                            <div class="text-center" style="width:100%;">
                                <v-img max-height="64" :src="require('../assets/images/pdf.png')" contain @click="exportReport('pdf')" style="cursor:pointer;"></v-img>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="6" style="border-left:1px solid #cdcdcd;">
                            <div class="text-center" style="width:100%;">
                                <v-img max-height="64" :src="require('../assets/images/excel.png')" contain @click="exportReport('csv')" style="cursor:pointer;"></v-img>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="exportDialog = false" text>Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Parameter from './Parameter.vue';

export default {
    props: {
        parameters: {       // array of objects. Object definition { type: 'date|store|select', key: '', label: '', value: '', visible: true|false, icon: '', options: [] }
            type: Array,
            default: () => {
                return [];
            }
        },
        hideParameters: {   // whether to hide the parameters from the user
            type: Boolean,
            default: false,
        },
        parameterPosition: {         // position of the parameters. Possible values top, left, right, buttom
            type: String,
            default: 'top'
        },
        title: {
            type: String,
            default: 'Report Title'
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localVisibleParameters: [],
            exportDialog: false
        }
    },
    computed: {
        visibleParameters() {
            let param = this.parameters.filter(p => {
                return p.visible === true;
            });

            if( param == undefined || param == null )
                return [];
            
            return param;
        }
    },
    watch: {
        
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.visibleParameters.forEach(p => {
                this.localVisibleParameters.push({
                    key:    p.key,
                    value:  p
                });
            });
        },
        exportReport( format ) {
            // emit the export event
            this.$emit( 'exportReport', format );

            // this.exportDialog = false;
        }
    },
    components: {
        Parameter
    }
}
</script>

<style scoped>

</style>
